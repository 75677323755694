import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import { additionalservicesSEO } from "../components/data/seo";
import {
  container,
  preview,
  stock,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";

function Additionalservices() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={additionalservicesSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={container}>
          <div className={preview}>
            <h2 className={title}>Дополнительные услуги</h2>
            <p className={description}>от 350₽</p>
            <StaticImage
              src="../images/services/8.jpg"
              alt="обложка для Дополнительных услуг"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>Закажите клининг по акции!</h3>
            <p>
              При заказе любого типа помещения - скидка до 30% на услуги
              химчистки мягкой мебели
            </p>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать уборку <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={price}
            style={{
              background: "linear-gradient(180deg, #D15842 0%, #1D00A8 100%)",
            }}
          >
            <h2 className={title}>Прайс</h2>
            <p className={description}>
              Для расчета более точной цены, оставьте заявку
            </p>
            <p className={list}>
              - Помыть холодильник внутри от 1000₽
              <br />
              - Кухонный гарнитур внутри от 400₽
              <br />
              - Люстра (сложной формы) от 400₽
              <br />
              - Глажка белья от 500₽/час
              <br />
              - Духовой шкаф / 500₽
              <br />- Микроволновка / 350₽
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Прочие услуги:</h3>
        <Otherservice hide={7} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="дополнительные услуги"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Additionalservices;
